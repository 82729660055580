import React from 'react';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';

const DonateFaqPage = () => {
    return (
        <Layout>
            <Sidebar />
            <div className="container-fluid p-0" id="page-top">
                <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
                    <div className="w-100">
                        <h1 className="mb-0">Thank You for <span className="text-primary">Donating!</span></h1>
                        <h2 className="mb-5">Lambda Iota Alumni Association</h2>
                        <p className="mb-5">Insert FAQ here.</p>
                    </div>
                </section>
            </div>
        </Layout>
    )
};

export default DonateFaqPage;
